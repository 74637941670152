const applyConfig = (config) => {
  config.settings.DSGVOBanner.modules = ['youtube'];
  config.settings = {
    ...config.settings,
    supportedLanguages: ['de', 'en'],
    defaultLanguage: 'de',
    navDepth: 2,
    enableFatMenu: true,
  };
  config.settings.image_crop_aspect_ratios = [
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '1:1',
      ratio: 1,
    },
  ];
  return config;
};

export default applyConfig;
